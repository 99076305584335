import React from 'react';
import { CtaSectionExtended } from 'modules/cta-section-extended';

export const PropTechCtaSectionExtended = () => {
    return (
        <CtaSectionExtended title="view-prop-tech-real-estate.ctaExtendedTitle">
            <p>
                Unlock the full potential of your real estate operations with
                innovative, custom-built software designed to meet your unique
                needs.
            </p>
            <ul>
                <li>Free consultation for tailored proptech solutions</li>
                <li>
                    Discover how custom software can optimize your real estate
                    operations
                </li>
                <li>
                    Expertise in property management platforms and real estate
                    marketplaces
                </li>
                <li>Enhance client experience with cutting-edge technology</li>
                <li>Streamline property management, sales, and analytics</li>
            </ul>
        </CtaSectionExtended>
    );
};
